export function balance({routeAccount, siteVar}) {
  routeAccount.children.push({
    path: "balances",
    name: "AccountBalancesView",
    component: () => import("@/views/AccountBalancesView.vue"),
    meta: {
      lang: siteVar.lang.default,
      requiresAuth: true,
    },
    children: [
      {
        path: "dashboard",
        name: "AccountBalancesDashboardView",
        component: () => import("@/views/AccountBalancesDashboardView.vue"),
        meta: {},
      },
      {
        path: "balances/:balanceId",
        name: "AccountBalancesBalanceView",
        component: () => import("@/views/AccountBalancesBalanceView.vue"),
        meta: {},
      },
      {
        path: "entitlements/:entitlementId",
        alias: "cards/:entitlementId",
        name: "AccountBalancesEntitlementView",
        component: () => import("@/views/AccountBalancesEntitlementView.vue"),
        meta: {},
      },
    ],
  })
}
