import "./plugins/initSiteVarForLocalDev.js"
import {createApp} from "vue"
import {createMetaManager} from "vue-meta"
import App from "./App.vue"
import ods from "@jirizavadil/one-design-system"
import globalComponents from "@platformaone/common/app/plugins/globalComponents"
import localization from "@platformaone/common/app/plugins/localization"
import viewportListeners from "./plugins/viewportListeners"
import initSiteVarForAdminMode from "./plugins/initSiteVarForAdminMode.js"
import cartVersionCheck from "./plugins/cartVersionCheck"
import shop from "./plugins/shop.js"
import modeDesign from "./plugins/modeDesign.js"
import modeAdmin from "./plugins/modeAdmin.js"
import modeClient from "./plugins/modeClient.js"
import {useAppStore} from "@/stores/app.js"
import {createPinia} from "pinia"

// FIXME: these two imports cause "Uncaught Error: Component firestore has not been registered yet" on development and fail on production
// try https://vitejs.dev/guide/build.html#library-mode
// import {attachFirestore} from "@platformaone/common/app/stores/plugins/attachFirestore.js"
// import {resetState} from "@platformaone/common/app/stores/plugins/resetState.js"
import {attachFirestore} from "./plugins/attachFirestore.js"
import {resetState} from "./plugins/resetState.js"

import "@/themes/themes.less"

window.app = {
  version: __VERSION__,
  build: __BUILD__,
  commit: __RELEASE_SHA__,
  date: __BUILD_DATE__,
}

const pinia = createPinia()
pinia.use(attachFirestore)
pinia.use(resetState)

const app = createApp(App)
const metaManager = createMetaManager()

app.use(metaManager)
app.use(globalComponents)
app.use(localization)
app.use(pinia)
app.use(viewportListeners)
app.use(ods)
app.use(initSiteVarForAdminMode)
app.use(cartVersionCheck)

if (window.site && window.site.shopId) {
  app.use(shop)
}

const appStore = useAppStore()

console.log("appStore mode:", appStore.mode)

if (appStore.mode == "design") {
  app.use(modeDesign)
} else if (appStore.mode == "admin") {
  app.use(modeAdmin)
} else {
  app.use(modeClient)
}
