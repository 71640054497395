import * as Sentry from "@sentry/vue"
import {BrowserTracing} from "@sentry/tracing"
import {CaptureConsole as CaptureConsoleIntegration} from "@sentry/integrations"
import {router} from "@/router"

export default {
  install(app) {
    if (["staging", "production"].includes(import.meta.env.VITE_ENVIRONMENT)) {
      let integrations = [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router.r()),
          attachProps: true,
          logErrors: true, // passthrough to Vue logError handler
        }),
        new CaptureConsoleIntegration({
          // levels: ['log', 'info', 'warn', 'error', 'debug', 'assert']
          levels: ["error", "warn"],
        }),
      ]

      integrations.push(
        new Sentry.Replay({
          maskAllText: false,
          block: ["iframe"],
          blockAllMedia: false,
        })
      )

      // there is 'ignoreErrors' but it doesn't work for warnings
      const ignoreMessages = [
        "WebChannelConnection RPC", // https://platformaone.sentry.io/issues/4151744648
        "Google Maps JavaScript API has been loaded directly without loading=async", // https://platformaone.sentry.io/issues/4937698362
        "You may test your Stripe.js integration over HTTP", // local_dev only
      ]

      Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        release: "app-client@__VERSION__+__BUILD__",
        environment: import.meta.env.VITE_ENVIRONMENT,
        debug: false,
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations,
        denyUrls: [
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
          // Facebook flakiness
          /graph\.facebook\.com/i,
        ],
        beforeSend(event, hint) {
          const message = event.message || hint.originalException?.message || hint.originalException

          // ignore messages that contain part of one of the ignoreMessages
          if (ignoreMessages.some((m) => message?.includes(m))) {
            console.log("🔴🔴🔴 ignoring message", message)
            return null
          }
        },
      })
    }
  },
}
