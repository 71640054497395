import {createAuthClient} from "better-auth/vue"
import {emailOTPClient} from "better-auth/client/plugins"

export const {signIn, signUp, signOut, useSession, emailOtp} = createAuthClient({
  baseURL: `${window.location.origin}/api/v1/auth`,
  // baseURL: `${import.meta.env.VITE_URL_API}/v1/auth`, // old
  // baseURL: "http://localhost:4011/api/v1/auth", // new
  // baseURL: "https://pfm-app-client.eu.ngrok.io/api/v1/auth", // ngrok
  fetchOptions: {
    headers: {
      "x-scope-org": window.site?.scope?.orgId,
      "x-scope-space": window.site?.scope?.spaceId,
      "x-scope-environment": window.site?.scope?.environmentId,
    },
  },
  plugins: [emailOTPClient()],
})
