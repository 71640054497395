import {useAppStore} from "@/stores/app"

// init siteVar for admin mode
export default {
  install() {
    const appStore = useAppStore()
    const query = window.location.search
    const urlParams = new URLSearchParams(query)
    const mode = urlParams.get("mode")

    const siteId = urlParams.get("siteId")
    const shopId = urlParams.get("shopId") || false

    if (mode) appStore.app.mode = mode

    if (!window.site) window.site = {}
    if (siteId) window.site.id = siteId
    if (shopId) window.site.shopId = shopId
  },
}
