export function shop({routes, siteFeatures, routeAccount}) {
  // feature: videos on demand
  if (siteFeatures?.global?.shop.videosOnDemand) {
    routeAccount.children.push({
      path: `videos`,
      name: `AccountVideosView`,
      component: () => import("@/views/AccountVideosView.vue"),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: `:videoId`,
          name: "AccountVideosVideoView",
          component: () => import("@/views/AccountVideosVideoView.vue"),
          meta: {},
        },
      ],
    })
  }

  // add product to cart
  routes.push({
    path: `/add/:productSKU`,
    name: `add`,
    component: () => import("@/components/Add.vue"),
  })

  // download invoice / giftCard / product from emailed link
  routes.push({
    path: "/download/:kind", // invoice | giftCard | product (digital)
    name: "DownloadView",
    component: () => import("@/views/DownloadView.vue"),
  })
}
