export default {
  text: {
    "auth-signIn-magicLinkSentTo": "magic link an",
    "auth-signIn-magicLinkWrongEmail": "falsche email?",
    "auth-signIn": "anmelden",
    "auth-signOut": "abmelden",

    "account-header": "konto",

    "account-videos-header": "videos",

    // countries
    "country-CZ": "Tschechische Republik",
    "country-SK": "Slowakei",
    "country-AT": "Österreich",
    "country-DE": "Deutschland",

    // languages
    "language-cs": "tschechisch",
    "language-en": "englisch",
    "language-de": "deutsch",

    // languages (in)
    "language-in-cs": "tschechisch",
    "language-in-en": "englisch",
    "language-in-de": "deutsch",

    "buy-for": "kaufen für",
    buy: "kaufen",
    "preorder-for": "vormerken für",
    preorder: "vormerken",
    "fill-form-first": "Formular abschließen",
    "sold-out": "ausverkauft",
    "cart-remove": "entfernen",
    "cart-price-free": "kostenlos",
    "cart-price-from": "ab",
    "cart-orders-closed": "Bestellungen geschlossen",
    "cart-slot-full": "Slot ist voll",
    "cart-pick-less-items": "weniger Artikel auswählen",
    "cart-continue-shopping": "Einkauf fortsetzen",
    "cart-order-in": "bestellen in",
    "cart-calendar-mo": "mo",
    "cart-calendar-tu": "di",
    "cart-calendar-we": "mi",
    "cart-calendar-th": "do",
    "cart-calendar-fr": "fr",
    "cart-calendar-sa": "sa",
    "cart-calendar-su": "so",
    "cart-calendar-yday": "gestern",
    "cart-calendar-today": "heute",
    "cart-calendar-tmrw": "morgen",
    "cart-form-promocode-invalid": "Ungültiger Code",
    "cart-form-optional": "optional",
    "cart-form-mistyped-something": "etwas falsch eingegeben?",
    "cart-form-street-number-missing": "Wir bräuchten eine Hausnummer",
    "cart-form-address-restricted": "Leider liefern wir hier nicht",
    "cart-form-phone-missing": "Wir bräuchten eine Telefonnummer",
    "cart-form-email-missing": "Wir bräuchten eine E-Mail-Adresse",
    "cart-form-name-missing": "Wir bräuchten einen Namen",
    "cart-form-surname-missing": "Wir brauchen Vor- und Nachnamen",
    "cart-form-street-missing": "Wir bräuchten eine Straße",
    "cart-form-city-missing": "Wir bräuchten eine Stadt",
    "cart-form-city-invalid": "So sieht eine Stadt nicht aus",
    "cart-form-postal-missing": "Wir bräuchten eine Postleitzahl",
    "cart-form-postal-invalid": "So sieht keine Postleitzahl aus",
    "cart-form-emoji-not-allowed": "Emojis sind nicht erlaubt",
    "cart-order-pay": "bezahlen",
    "cart-order-order-for": "bestellen für",
    "cart-order-failed-something-wrong": "etwas ist schiefgelaufen :(",
    "cart-order-check-invalid-fields": "überprüfe ungültige Felder",
    "cart-payment-cardonline-processedby":
      "Karte wird verarbeitet von <a href='%0' target='_blank'>%1</a>,<br>wir sehen ihre Details nicht.",
    "cart-payment-cardonline-testmode": "Testmodus",
    "cart-payment-cardonline-testmode-use-card": "Karte verwenden",

    // if you change this, change the admin translation file
    "cart-main-title": "Warenkorb",
    "cart-header-backToShop": "Weiter einkaufen",
    "cart-itemList-empty": "Nichts hier. 😔 Wähle etwas 🙃",
    "cart-promoCode-title": "Promo-Code",
    "cart-promoCode-insert": "Promo-Code verwenden",
    "cart-promoCode-field-promoCode-label": "Haben Sie einen Promo-Code?",
    "cart-promoCode-field-promoCode-placeholder":
      "Magischer Schlüssel, der Ihnen hilft, während Sie ausgeben. 😜",
    "cart-promoCode-field-promoCode-helpText": "ABRACADABRA",
    "cart-customerLogin-signInWithPasskey": "Mit Passkey anmelden",
    "cart-customerLogin-or": "oder",
    "cart-customerLogin-signInWithEmail": "mit E-Mail",
    "cart-customerLogin-signIn": "anmelden",
    "cart-customerLogin-signOut": "abmelden",
    "cart-customerSignUp-registerPasskey": "Passkey erstellen",
    "cart-customerSignUp-nextTimeUsePasskey": "Beim nächsten Mal können Sie sich mit Ihrem Passkey anmelden.",
    "cart-customerSignUp-nextTimeUseMagicLink": "Beim nächsten Mal können Sie sich mit Ihrer E-Mail anmelden.",
    "cart-customerSignUp-usePasswordInstead": "stattdessen Passwort setzen",
    "cart-delivery-title": "Lieferung",
    "cart-delivery-branch-title": "Filiale",
    "cart-delivery-undeliverableItemsCombination":
      "Diese Produktkombination kann nicht geliefert werden. Bitte bestellen Sie separat.",
    "cart-delivery-deliveryOptions": "Lieferoptionen",
    "cart-customerInfo-title": "Meine Infos",
    "cart-customerInfo-actionButtons-addBillingInfo": "Rechnungsinfo hinzufügen",
    "cart-customerInfo-fields-customerEmail-label": "E-Mail",
    "cart-customerInfo-fields-customerEmail-placeholder": "john@smith.com",
    "cart-customerInfo-fields-customerEmail-helpText":
      "Wir benötigen Ihre E-Mail, um Sie über den Status Ihrer Bestellung zu informieren.",
    "cart-customerInfo-fields-customerName-label": "Name und Nachname",
    "cart-customerInfo-fields-customerName-placeholder": "John Smith",
    "cart-customerInfo-fields-customerName-helpText": "",
    "cart-customerInfo-fields-customerPhone-label": "Telefon",
    "cart-customerInfo-fields-customerPhone-placeholder": "123 456 789",
    "cart-customerInfo-fields-customerPhone-helpText":
      "Wir benötigen Ihre Telefonnummer, um Sie im Falle eines unerwarteten Ereignisses schnell kontaktieren zu können.",
    "cart-billingInfo-title": "Rechnungsstellung",
    "cart-billingInfo-actionButtons-fillManually": "Manuell ausfüllen",
    "cart-billingInfo-actionButtons-companyAddress": "Firmenadresse",
    "cart-billingInfo-fields-billingAddress-label": "Adresse",
    "cart-billingInfo-fields-billingAddress-placeholder": "Hauptstraße 1, Prag",
    "cart-billingInfo-fields-billingAddress-helpText": "",
    "cart-billingInfo-fields-billingAddressStreet-label": "Straße",
    "cart-billingInfo-fields-billingAddressStreet-placeholder": "Hauptstraße 1, Prag",
    "cart-billingInfo-fields-billingAddressStreet-helpText": "",
    "cart-billingInfo-fields-billingAddressCity-label": "Stadt",
    "cart-billingInfo-fields-billingAddressCity-placeholder": "Prag",
    "cart-billingInfo-fields-billingAddressCity-helpText": "",
    "cart-billingInfo-fields-billingAddressPostal-label": "Postleitzahl",
    "cart-billingInfo-fields-billingAddressPostal-placeholder": "110 00",
    "cart-billingInfo-fields-billingAddressPostal-helpText": "",
    "cart-billingInfo-fields-billingAddressCompanyICO-label": "Registrierungsnummer",
    "cart-billingInfo-fields-billingAddressCompanyICO-placeholder": "123 45 678",
    "cart-billingInfo-fields-billingAddressCompanyICO-helpText": "",
    "cart-billingInfo-fields-billingAddressCompanyName-label": "Firma",
    "cart-billingInfo-fields-billingAddressCompanyName-placeholder": "Firma s.r.o.",
    "cart-billingInfo-fields-billingAddressCompanyName-helpText": "",
    "cart-billingInfo-fields-invoiceNote-label": "Rechnungshinweis",
    "cart-billingInfo-fields-invoiceNote-placeholder": "Geschenk für den Chef",
    "cart-billingInfo-fields-invoiceNote-helpText": "",
    "cart-deliveryInfo-title": "Lieferung",
    "cart-deliveryInfo-actionButtons-fillManually": "Manuell ausfüllen",
    "cart-deliveryInfo-actionButtons-companyAddress": "An eine Firma liefern",
    "cart-deliveryInfo-actionButtons-otherBillingInfo": "Andere Rechnungsinfo",
    "cart-deliveryInfo-actionButtons-addBillingInfo": "Rechnungsinfo hinzufügen",
    "cart-deliveryInfo-recipientSameAsCustomerOptions-true": "für mich",
    "cart-deliveryInfo-recipientSameAsCustomerOptions-false": "als Geschenk",
    "cart-deliveryInfo-fields-recipientName-label": "Name und Nachname",
    "cart-deliveryInfo-fields-recipientName-placeholder": "John Smith",
    "cart-deliveryInfo-fields-recipientName-helpText": "",
    "cart-deliveryInfo-fields-recipientPhone-label": "Telefon",
    "cart-deliveryInfo-fields-recipientPhone-placeholder": "123 456 789",
    "cart-deliveryInfo-fields-recipientPhone-helpText": "Wir benötigen ein Telefon für den Lieferanten.",
    "cart-deliveryInfo-fields-deliveryAddress-label": "Adresse",
    "cart-deliveryInfo-fields-deliveryAddress-placeholder": "Hauptstraße 1, Prag",
    "cart-deliveryInfo-fields-deliveryAddress-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressStreet-label": "Straße",
    "cart-deliveryInfo-fields-deliveryAddressStreet-placeholder": "Hauptstraße 1, Prag",
    "cart-deliveryInfo-fields-deliveryAddressStreet-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressCity-label": "Stadt",
    "cart-deliveryInfo-fields-deliveryAddressCity-placeholder": "Prag",
    "cart-deliveryInfo-fields-deliveryAddressCity-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressPostal-label": "Postleitzahl",
    "cart-deliveryInfo-fields-deliveryAddressPostal-placeholder": "110 00",
    "cart-deliveryInfo-fields-deliveryAddressPostal-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressCompanyICO-label": "Registrierungsnummer",
    "cart-deliveryInfo-fields-deliveryAddressCompanyICO-placeholder": "123 456 789",
    "cart-deliveryInfo-fields-deliveryAddressCompanyICO-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressCompanyName-label": "Firma",
    "cart-deliveryInfo-fields-deliveryAddressCompanyName-placeholder": "Firma s.r.o.",
    "cart-deliveryInfo-fields-deliveryAddressCompanyName-helpText": "",
    "cart-deliveryInfo-fields-deliveryNote-label": "Lieferhinweis",
    "cart-deliveryInfo-fields-deliveryNote-placeholder": "",
    "cart-deliveryInfo-fields-deliveryNote-helpText": "",
    "cart-deliveryInfo-fields-recipientNote-label": "Empfängerhinweis",
    "cart-deliveryInfo-fields-recipientNote-placeholder": "",
    "cart-deliveryInfo-fields-recipientNote-helpText": "",
    "cart-deliveryInfo-fields-contactless-labelTrue": "Kontaktlose Lieferung",
    "cart-deliverySlots-noMoreDaysAvailable": "keine anderen Tage<br>geplant 😔",
    "cart-deliverySlots-noMoreSlotsAvailable": "Keine freien<br>Zeitslots mehr verfügbar 😔",
    "cart-note-title": "Notiz",
    "cart-note-insert": "Notiz hinzufügen",
    "cart-note-fields-note-label": "Fällt Ihnen noch etwas ein?",
    "cart-note-fields-note-placeholder": "Laden Sie!",
    "cart-note-fields-note-helpText":
      "Wir werden versuchen, Ihnen den Mond zu geben! Wir sind jedoch Menschen, also 😜",
    "cart-payment-title": "Zahlung",
    "cart-stripeWallet-otherMethod": "Andere Methode",
    "cart-stripeWallet-total": "Gesamt",
    "cart-orderButton-terms-pre": "Mit der Zahlung stimme ich zu",
    "cart-orderButton-terms-link": "Bedingungen",
    "cart-confirmation-orderNumber-heading": "Bestellnummer",
    "cart-confirmation-email-heading": "Bestätigung",
    "cart-confirmation-merchantNote": "Wir freuen uns auf das nächste Mal!",
    "cart-confirmation-continueShoppingBtn": "etwas anderes? 😜",
    "cart-confirmation-watchVideoNow": "Video ansehen",
    "cart-confirmation-downloadNow": "herunterladen",

    // confirmation texts - these are configured in delivery methods
    "cart-confirmation-method-recipientSameAsCustomer-title": "Vielen Dank",
    "cart-confirmation-method-recipientSameAsCustomer-paragraph": "Ihre Bestellung wird bearbeitet.",
    "cart-confirmation-method-recipientNotSameAsCustomer-title": "Vielen Dank",
    "cart-confirmation-method-recipientNotSameAsCustomer-paragraph":
      "Ihre Bestellung wird bearbeitet und wird bald an den Empfänger versendet.",

    "delivery-method-constraints-label": "Lieferbeschränkungen",
    "delivery-method-constraints-min-dispatch-time": "Versendet nach",
    "delivery-method-constraints-shipping-from": "Versendet von",
    "delivery-method-constraints-shipping-to": "Versendet bis",

    main_title: "Verwaltung",

    "menu-pages": "Seiten",
    "menu-commerce": "Handel",
    "menu-analytics": "Analytik",
    "menu-settings": "Einstellungen",
    "menu-help": "Hilfe",
    "menu-logout": "Abmelden",
    "menu-publisher": "Verleger",
    "menu-designer": "Designer",

    "sites-main-headline": "Meine Sites",

    "option-yes": "Ja",
    "option-no": "Nein",

    monthNames: [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ],
    dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    dayNamesShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],

    "login-header": "Anmeldung",
    "login-header-label": "Hier anmelden...",
    "login-user-name": "Benutzername",
    "login-user-password": "Passwort",
    "login-user-email": "E-Mail",
    "login-submit-button": "Anmelden",
    "login-help": "Brauchen Sie Hilfe?",
    "login-use-pwd": "Passwort verwenden",
    "login-use-pwdless": "E-Mail-Link verwenden",
    "login-msg-welcome": "platforma.one",
    "login-error-wrong-password": "Falsches Passwort",
    "login-error-too-many-requests": "Zu viele Versuche, versuchen Sie es später erneut.",
    "login-validator-passwordlessemail-errormessage-notvalid":
      "Nicht nah genug. Etwas falsch geschrieben?",
    "login-validator-passwordlessemail-errormessage-notfound": "Wir haben Sie nicht gefunden. Sind Sie real?",
    "login-validator-passwordlessemail-errormessage-empty": "Hier gehört Ihre E-Mail-Adresse hin.",
    "login-validator-passwordemail-errormessage-bad": "Das",
    "login-validator-passwordemail-errormessage-empty": "Kommen Sie, seien Sie genauer.",
    "login-validator-passwordemail-errormessage-notvalid": "Das ist keine E-Mail!",
    "login-validator-password-errormessage-bad": "oder das ist nicht korrekt.",
    "login-validator-password-errormessage-empty": "Kommen Sie, Passwörter sind wichtig!",
    "login-email-instructions-headline": "Überprüfen Sie Ihre E-Mail",
    "login-email-sent-instructions-pt1": "Die Anmelde-E-Mail ist auf dem Weg zu",
    "login-email-sent-instructions-pt2": "",
    "login-email-sent-instructions-pt3": "Sie können dieses Fenster schließen.",
    "login-email-instructions-tryagain": "Versuchen Sie es erneut",

    "guest-signup-email-instructions-headline": "Wohoo, nur noch ein Schritt von Ihrer Website entfernt!",
    "guest-signup-email-sent-instructions-pt1":
      "Der Bestätigungslink zur Veröffentlichung Ihrer Website wurde an",
    "guest-signup-email-sent-instructions-pt2":
      "Sie können dieses Fenster schließen, es wird ein neues geöffnet, wenn Sie den Link erhalten.",
    "guest-signup-published-headline": "Es ist soweit!",
    "guest-signup-published-text": "Ihre Website ist live unter",
    "guest-signup-published-open-btn": "Schauen Sie es sich an",

    "main-site-menu": "Hauptmenü",

    "settings-header": "Einstellungen",
    "site-config-title": "Seitentitel",

    "pages-header": "Seiten",
    "page-list": "Seitenliste",
    "main-view-site": "Website anzeigen",
    "page-selected-layout": "Layout",
    "page-selected-content": "Inhalt",
    "page-selected-settings": "Einstellungen",
    "page-selected-styles": "Stile",
    "page-setup-title": "Titel",
    "page-setup-role": "Erforderliche Rolle",
    "page-setup-description": "Beschreibung",
    "page-setup-slugs": "Slugs",
    "page-setup-visible": "Sichtbar",
    "page-button-delete": "Seite löschen",
    "page-add-hint": "Klicken Sie auf einen Punkt, um einzufügen",
    "page-duplicate": "Duplizieren",
    "page-duplicate-parent": "Übergeordnete Seite",
    "page-duplicate-new-title": "Neuer Seitentitel",
    "page-duplicate-execute": "Ausführen",
    "page-clone-fill_name": "Füllen Sie den Seitennamen für die Sprache aus: ",
    "page-clone-parent_no_slug": "Übergeordnete Seite hat keinen Slug!",
    "page-clone-slag_for_lang": "Übergeordnete Seite hat keinen Slug für die Sprache: ",
    "page-clone-done": "Duplizierung abgeschlossen.",
    "page-clone-error": "Duplizierung fehlgeschlagen!",
    "page-block-styling": "Stilisierung",
    "label-add-language-version": "Sprachversion hinzufügen",

    "commerce-header": "E-Shop",
    "commerce-shop-name": "Shopname",
    "commerce-no-site": "Wählen Sie zuerst die Website aus",
    "commerce-new-eshop": "Neuen E-Shop erstellen",
    "commerce-orders": "Bestellungen",
    "commerce-inventory": "Lagerbestand",
    "commerce-shop-items": "Shopartikel",
    "commerce-customers": "Kunden",
    "commerce-subscriptions": "Abonnements",
    "commerce-orders-setup": "Einstellungen",
    "commerce-orders-flow-setup": "Bestellablauf einrichten",
    "commerce-shop-create-model": "Neues Produktformular erstellen",
    "commerce-shop-update-model": "Produktformular aktualisieren",
    "commerce-products-header": "Produkte",
    "commerce-product-attr-name": "Feldname",
    "commerce-product-model-name": "Produkttypname",
    "commerce-shop-create-product": "Neues Produkt erstellen",
    "commerce-shop-update-product": "Produktdetails aktualisieren",
    "commerce-product-header": "Produkt",
    "commerce-fee-reports": "Gebührenberichte",
    "commerce-no-products-available": "keine Produkte",
    "commerce-display-all": "alle anzeigen",
    "commerce-go-to-cart": "zur Kasse",
    "product-created-info": "Produkt wurde erstellt.",
    "product-not-created-error": "Ein Problem verhinderte die Erstellung des Produkts!",
    "product-saved-info": "Produkt wurde aktualisiert.",
    "product-not-saved-error": "Ein Problem verhinderte die Änderung des Produkts!",
    "product-question-delete": "Möchten Sie das Produkt (inklusive Varianten) wirklich entfernen?",
    "product-image-main": "Hauptprodukt",
    "product-image-variant": "Hauptvariante",
    "product-fees": "Gebühren",
    "upload-file-in-process": "Datei wird hochgeladen",
    "commerce-setup": "Commerce-Einstellungen",
    "commerce-supported-curr": "Unterstützte Währungen",
    "commerce-default-curr": "Standardwährung",
    "commerce-supported-langs": "Unterstützte Sprachen",
    "commerce-default-lang": "Standardsprache",
    "commerce-order-num-format": "Bestellnummerformat",
    "commerce-order-num-format-hint": "...",
    "commerce-sell-units": "Mögliche Verkaufseinheiten",
    "commerce-sell-units-hint": "...",
    "commerce-bank-connection": "Bankverbindung",
    "commerce-bank-name": "Bankname",
    "commerce-bank-code": "Bankleitzahl",
    "commerce-account-number": "Kontonummer",
    "commerce-iban": "IBAN",
    "commerce-swift": "SWIFT",
    "commerce-company-address": "Firmenadresse",
    "commerce-setup-payments": "Zahlungseinstellungen",
    "commerce-payment-methods": "Zahlungsmethoden",
    "commerce-payment-method-add": "Weitere Zahlungsmethode hinzufügen",
    "commerce-tax-values": "Verfügbare Steuersätze",
    "commerce-delivery-channels": "Unterstützte Liefermethoden",
    "commerce-setup-deliveries": "Lieferoptionen einrichten",
    "commerce-delivery-method-add": "Weitere Liefermethode hinzufügen",
    "commerce-setup-delivery-planning-settings": "Lieferplanungseinstellungen",
    "commerce-setup-delivery-planning": "Lieferplanung",
    "commerce-delivery-next-day-order": "Letzte Bestellung für den nächsten Tag um:",
    "commerce-delivery-next-days": "Nächste Tage anzeigen:",
    "commerce-delivery-use-defaults": "Standardeinstellungen verwenden",
    "commerce-delivery-add-slot": "+ Slot hinzufügen",
    "commerce-delivery-additional-fields": "Zusätzliche Felder im Warenkorb",
    "commerce-delivery-add-field": "+ Feld hinzufügen",
    "commerce-delivery-planning": "Lieferplanung",
    "commerce-delivery-default-slots": "Schablone für Slots",
    "commerce-setup-delivery-planning-slots": "Aktive Slots",
    "commerce-delivery-settings": "Einstellungen",
    "commerce-flow-delete-error": "Löschen nicht möglich. Es wird in: [%0] verwendet.",
    "commerce-flow-cmd-set": "Wert setzen",
    "commerce-flow-cmd-exe": "Operation ausführen",
    "commerce-flow-operation-request-liftago": "Liftago anfordern",
    "commerce-terms-agreement":
      'Mit der Zahlung akzeptiere ich <a target="_blank" href="%0">die Nutzungsbedingungen</a>',
    "commerce-product-not-found": "Produkt nicht gefunden",
    "commerce-product-video-vod-full-avilable-after-purchase":
      "Das vollständige Video wird sofort nach dem Kauf in Ihrem Benutzerkonto verfügbar.",

    "commerce-courier-liftago-courierPosition": "Standort des Kuriers",
    "commerce-courier-liftago-rideStatus-PROCESSING": "Fahrer suchen",
    "commerce-courier-liftago-rideStatus-ACCEPTED": "Fahrer ausgewählt",
    "commerce-courier-liftago-rideStatus-WAITING": "Fahrer wartet auf Abholung",
    "commerce-courier-liftago-rideStatus-IN_PROGRESS": "Güter an Bord",
    "commerce-courier-liftago-rideStatus-COMPLETED": "Lieferung abgeschlossen",
    "commerce-courier-liftago-rideStatus-CANCELLED": "Lieferung abgebrochen",
    "commerce-courier-liftago-rideStatus-REJECTED": "Kein Fahrer gefunden",
    "commerce-courier-liftago-stopState-IN_QUEUE": "In Lieferung",
    "commerce-courier-liftago-stopState-DELIVERED": "Geliefert",
    "commerce-courier-liftago-stopState-UNDELIVERED": "Nicht geliefert",

    "stripe-error-card-expired": "Karte abgelaufen. Bitte verwenden Sie eine andere Karte.",
    "stripe-decline-card-expired": "Karte abgelaufen. Bitte verwenden Sie eine andere Karte.",
    "stripe-decline-auth-required": "Zahlung benötigt Autorisierung. Bitte versuchen Sie es erneut.",
    "stripe-decline-try-again": "Bitte versuchen Sie es erneut oder kontaktieren Sie Ihre Bank.",
    "stripe-decline-contact-bank": "Bitte kontaktieren Sie Ihre Bank.",
    "stripe-decline-duplicate": "Doppelte Zahlung.",
    "stripe-decline-incorrect-number": "Falsche Kartennummer",
    "stripe-decline-incorrect-cvc": "Falsches CVC",
    "stripe-decline-incorrect-expiry": "Falsches Ablaufdatum",
    "stripe-decline-insufficient-funds": "Bitte überprüfen Sie Ihr Konto.",
    "stripe-decline-test-card": "Verwenden Sie keine Testkarte.",
    "stripe-decline-test-mode-live-card": "Live-Karte in Testmodus verwendet",

    "address-street": "Straße",
    "address-number": "Hausnummer",
    "address-area": "Gebiet",
    "address-city": "Stadt",
    "address-zip": "PLZ",
    "address-region": "Region",
    "address-country": "Land",
    "commerce-invoice-disclaimer": "Rechnungshinweis",

    "product-field-title": "Titel",
    "product-field-description": "Beschreibung",
    "product-tags": "Schlagwörter",
    "product-sku": "SKU",
    "product-options": "Optionen",
    "product-images": "Bilder",
    "product-quantity": "Lagerbestand & Sichtbarkeit",
    "product-shipping": "Versand",
    "product-shipping-available-deliveries": "Verfügbare Liefermethoden",
    "product-channels": "Kanäle",
    "system-settings": "Systemeinstellungen",
    "other-settings": "Andere",
    "channel-online": "Online",
    "product-files": "Dateien",
    "add-a-new": "Ein weiteres hinzufügen:",
    "button-add-variant": "Variante hinzufügen",
    "button-add-product": "Produkt",
    "button-add-option": "+ Option hinzufügen",
    "button-add-attribute": "+ Attribut hinzufügen",
    "product-field-type": "Typ",
    "product-field-name": "Name",
    "question-product-opt-delete": "Möchten Sie diese Option wirklich löschen?",
    "question-product-product-delete": "Möchten Sie dieses Produkt wirklich löschen?",
    "question-product-product-variants-delete":
      "Möchten Sie dieses Produkt und seine Varianten wirklich löschen?",
    "product-enable-stock-track": "Lagerbestand verfolgen",
    "product-min-order": "Mindestbestellmenge:",
    "product-when-sold-out": "Wenn ausverkauft:",
    "product-on-sold-hide": "Verstecken",
    "product-on-sold-disable": "Beschränken",
    "product-on-sold-preorder": "Vorbestellen",
    "product-show-as": "Sichtbarkeit",
    "product-show-as-hide": "Versteckt",
    "product-show-as-unavailable": "Nicht verfügbar",
    "product-show-as-available": "Verfügbar",
    "product-show-as-auto": "Automatisch",
    "product-show-stock-counts": "Lagerbestandszahlen anzeigen",
    "product-pricing": "Preise",
    "product-enable-reservation": "Reservierung erlauben",
    "product-reservation-timeout": "Zeit, um das Produkt im Warenkorb für den Kauf zu halten",
    "commerce-pricing-base": "Basis",
    "commerce-pricing-tax": "Steuern",
    "commerce-pricing-final": "Endgültig",
    "commerce-pricing-purchased": "Erworben",
    "commerce-pricing-commission": "Provision",
    "commerce-pricing-profit": "Gewinn",
    "shipping-weight": "Gewicht [kg]",
    "shipping-dimensions": "Abmessungen [cm]",
    "question-product-file-attr-delete": "Möchten Sie dieses Attribut wirklich löschen?",
    "question-product-file-delete": "Möchten Sie diese Datei wirklich löschen?",

    "preorder-starts-in": "Vorbestellung beginnt in",
    "preorder-ends-in": "Vorbestellung endet in",
    "preorder-ended": "Vorbestellung beendet",
    "preorder-error-tooSoon":
      "Die Bestellung enthält ein Produkt für eine Vorbestellung, die noch nicht begonnen hat. Entfernen Sie es oder bestellen Sie später.",
    "preorder-error-tooLate":
      "Die Bestellung enthält ein Produkt für eine Vorbestellung, die bereits beendet wurde. Entfernen Sie es und bestellen Sie erneut.",

    "signup-email": "Was ist Ihre E-Mail?",
    "signup-site-domain": "Was wird Ihre Website-Domain sein?",
    "signup-done": "... überprüfen Sie Ihren E-Mail-Posteingang.",

    "products-prepared": "Bereit -> Gesendet",

    "button-cancel": "Abbrechen",
    "button-confirm": "Bestätigen",
    "button-remove": "Entfernen",
    "button-save-draft": "Entwurf speichern",
    "button-publish": "Veröffentlichen",
    "button-publish-all": "Alle veröffentlichen",
    "button-save": "Speichern",
    "button-add": "Hinzufügen",
    "button-add-file": "Datei hinzufügen",
    "button-change-file": "Datei ändern",
    "button-add-font": "Schriftart hinzufügen",
    "button-delete": "Löschen",
    "button-all": "Alle",
    "button-signup": "Anmelden",

    "order-incomming-new": "Neue Bestellung!",
    // "order-incomming-new" : "Neue Bestellung! [%0]",
    "orders-customer": "Kunde",
    "orders-products": "Produkte",
    "orders-shipping": "Versand",
    "order-payment-not-paid": "Noch nicht bezahlt",
    "order-payment-paid": "Bezahlt am:",
    "order-customer-note": "Kundennotiz",
    "order-customer-notify": "Kunden benachrichtigen",
    "order-note-add": "Notiz hinzufügen",
    "order-pay-products": "Produkte",
    "order-pay-delivery": "Versand",
    "order-pay-payment": "Zahlung",
    "order-pay-discount": "Rabatt",
    "order-pay-total": "Kunde zahlt",
    "order-pay-method-card-online": "Karte online",
    "order-pay-method-card-site": "Karte im Laden",
    "order-pay-method-card-deliv": "Karte bei der Lieferung",
    "order-pay-method-cash-site": "Bar im Laden",
    "order-pay-method-cash-deliv": "Bar bei der Lieferung",
    "order-pay-method-bank-wire": "Überweisung",
    "order-pay-method-paypal": "Paypal",
    "order-shipping-method-branch": "Filialabholung",
    "order-shipping-method-messanger": "Kurier",
    "order-shipping-method-ppl": "PPL",
    "order-shipping-method-ups": "UPS",
    "order-shipping-method-zasilk": "Zásilkovna",
    "order-shipping-method-cpost": "Tschechische Post",
    "order-shipping-method-cpost-ob": "Tschechische Post - Geschäftspaket",
    "order-shipping-method-custom": "Eigene",
    "order-shipping-method-": "",

    oa_state_unpaid: "Unbezahlt",
    oa_state_paid: "Bezahlt",
    oa_state_preparing: "Produkte vorbereiten",
    oa_state_printing: "Etiketten drucken",
    oa_state_packing: "Verpacken",
    oa_state_dispatching: "Versenden",
    oa_state_sent: "Versendet",
    oa_state_pickup: "Zur Abholung bereit",
    oa_state_refunding: "Rückzahlung",
    oa_state_cancelled: "Storniert",
    oa_state_archived: "Archiviert",
    oa_go_paid: "Wurde bezahlt",
    oa_go_prepare: "Produkte vorbereiten",
    oa_go_print: "Etikett drucken",
    oa_go_pack: "Verpacken",
    oa_go_cancel: "Stornieren",
    oa_go_dispatch: "Versenden",
    oa_go_send: "Senden",
    oa_go_pickup: "Zur Abholung bereitstellen",
    oa_go_refund: "Rückzahlung",
    oa_go_archive: "Archivieren",
    oa_go_open: "Öffnen",

    "billing-address": "Rechnungsadresse",
    "delivery-address": "Lieferdetails",

    "block-config-name": "Blockname",
    "block-config-tag": "Tag",
    "block-config-shared": "Gemeinsam",
    "block-config-region": "Region",
    "block-config-viewports": "Ansichten",

    "forms-field-id": "ID",
    "forms-field-type": "Feldtyp",
    "target-window-same": "Selbes Fenster",
    "target-window-new": "Neues Fenster",

    "form-font-name": "Schriftname",
    "form-font-weight": "Schriftstärke",
    "form-font-url": "Schrift-URL",
    "form-font-source-file": "Schriftdatei",

    "page-create-new": "Neue Seite",
    "page-create-new-abort": "Neue Seite abbrechen",
    "page-create-new-info": "Wählen Sie jetzt die übergeordnete Seite der neuen aus.",
    "page-new-name": "Neue Seite",
    "page-new-slug": "neuseite",
    "page-new-created": "Neue Seite erstellt",
    "page-new-not-created": "Die Seite wurde nicht erstellt.",
    "shared-blocks-label": "Gemeinsam",
    "private-blocks-label": "Privat",

    "settings-css-header": "Stile",
    "settings-scripts-header": "Skripte",
    "settings-config-header": "Stile-Konfiguration",
    "settings-fonts-header": "Schriften",

    "template-editor": "Vorlagen",
    "theme-label-fonts": "Schriften",
    "theme-label-scripts": "Skriptdateien",

    "field-listing-source": "Quelle",
    "field-listing-root-page": "Wurzelseite",

    "question-publish-all": "Wollen Sie wirklich alles veröffentlichen?",
    "message-info-site-published": "Die Seite wurde veröffentlicht.",
    "error-login-nonce-request":
      "Login fehlgeschlagen. Bitte wiederholen Sie den Prozess noch einmal, und wenn es auch dann nicht funktioniert, bitten wir Sie, uns zu kontaktieren.",
    "error-email-invlaid": "Die E-Mail-Adresse ist nicht gültig!",
    "error-email-not-found": "Die E-Mail-Adresse wurde nicht gefunden!",
    "error-sitemName-already-used": "Dieser Seitename wird bereits verwendet!",
    "error-email-already-used": "Diese E-Mail-Adresse wird bereits verwendet!",
    "error-both-already-used": "Beide Werte werden bereits verwendet!",
    "error-invlaid-values": "Das Formular ist nicht ordnungsgemäß ausgefüllt!",

    // block selector
    sections: "sektionen",
    "section-main": "haupt",
    "section-commerce": "handel",
    "section-shared": "geteilt",
    "section-custom": "benutzerdefiniert",
    "section-cancel": "abbrechen",
    "section-main-hero": "held",
    "section-main-space": "raum",
    "section-main-separator": "trenner",
    "section-main-header": "kopfzeile",
    "section-main-cards": "karten",
    "section-main-button": "knopf",
    "section-main-footer": "fußzeile",
    "section-main-menu": "menü",
    "section-main-gridList": "gitterliste",
    "section-main-logoCloud": "logowolke",
    "section-main-popup": "pop-up",
    "section-main-headerText": "text mit kopfzeile",
    "section-main-gallery": "galerie",
    "section-main-instagram": "instagram",
    "section-main-text": "text",
    "section-main-image": "bild",
    "section-main-imageText": "bild mit text",
    "section-main-video": "video",
    "section-main-team": "team",
    "section-commerce-productListing": "produktliste",
    "section-commerce-productDetail": "produkt detail",
    "section-commerce-productPriceSlider": "produkt mit freiwilligem preis",
    "section-commerce-productImages": "produktbilder",

    "product-variant": "variant",

    "priceVoluntary-voluntary": "Andere Menge",
    "priceVoluntary-price": "Setzen Sie den Wert.",
    "giftCard-delivery": "An wen senden wir es?",
    "giftCard-dispatchToCustomer": "mir",
    "giftCard-dispatchToCustomer-info":
      "Wir senden sofort nach Zahlungseingang eine A4-PDF an die im Warenkorb eingegebene E-Mail-Adresse.",
    "giftCard-dispatchToRecipient": "jemand anderes",
    "giftCard-dispatchToRecipient-info":
      "Wir senden sofort nach Zahlungseingang einen E-Mail-Gutschein an den Empfänger.",
    "giftCard-design": "Wählen Sie ein Design.",
    "giftCard-message": "Personalisieren Sie mit einer Nachricht.",
    "giftCard-message-placeholder": "Alles Gute zum Geburtstag!",
    "giftCard-download-preview": "Vorschau herunterladen",
    "giftCard-recipient": "Empfänger",
    "giftCard-recipientName": "Empfängername",
    "giftCard-recipientName-placeholder": "Peter",
    "giftCard-recipientEmail": "Empfänger-E-Mail",
    "giftCard-recipientEmail-placeholder": "peter@gifted.com",
    "giftCard-sender": "Absender",
    "giftCard-senderName": "Absendername",
    "giftCard-senderName-placeholder": "Anna",
    "giftCard-senderEmail": "Absender-E-Mail",
    "giftCard-senderEmail-placeholder": "anna@good.com",
    "giftCard-deliverTo": "Liefern an",
    "giftCard-language": "Sprache",
    "digitalProduct-download-sample": "Kostenlose Probe herunterladen",

    "booking-guests": "Gäste",
    "booking-date-slot": "Datum und Zeit",
    "booking-no-slots-available": "Keine Termine verfügbar",
    "booking-date": "Datum",
    "booking-slot": "Zeit",
    "booking-name": "Name",
    "booking-name-placeholder": "Peter Schmidt",
    "booking-email": "E-Mail",
    "booking-email-placeholder": "peter@schmidt.de",
    "booking-phone": "Telefon",
    "booking-phone-placeholder": "123 456 789",
    "booking-note": "Notiz",
    "booking-note-placeholder": "",
    "booking-preferred-language": "Bevorzugte Sprache",
    "booking-all-guests-are-adult": "Alle Gäste sind 18+",

    "page-terms-heading": "Allgemeine Geschäftsbedingungen",
  },
}
