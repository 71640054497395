<template>
  <div id="app" v-if="siteVarFetched">
    <!-- vue-meta injector component -->
    <metainfo>
      <template v-slot:title="{content}">{{ content }}</template>
    </metainfo>

    <!-- if site has commerce, load commerce cart component -->
    <Cart v-if="siteHasShop" />

    <!-- banner demo -->
    <div
      class="sticky top-0 z-50 flex w-full bg-orange-400 p-1 flex-center"
      v-if="siteVar.global.site.bannerDemo"
    >
      <span class="text-xs uppercase tracking-wide text-white">demo</span>
    </div>

    <!-- auth popup -->
    <AuthPopup />

    <!-- main view -->
    <div id="main" :class="appThemeClass">
      <RouterView v-if="showRouterView" />
    </div>

    <!-- development -->
    <DesignSystem v-if="showDesignSystem" />
    <DevelopmentPanel v-if="showDevelopmentPanel" />
    <DevtoolsConnect v-if="showDevtoolsConnect" />
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue"
import {useAppStore} from "@/stores/app"
import {useSiteStore} from "@/stores/site"
import {useUserStore} from "@/stores/user"
import {storeToRefs} from "pinia"
import {provide, computed, watch, ref} from "vue"
import {useRoute, useRouter} from "vue-router"
import {useMeta} from "vue-meta"
import {init as sinkInit} from "./sink/index.js"
import {initPlatformaone} from "@platformaone/common"

export default {
  name: "app",
  components: {
    Cart: defineAsyncComponent(() => import("@/components/elements/commerce/Cart.vue")),
    DesignSystem: defineAsyncComponent(() => import("@/components/DesignSystem.vue")),
    DevelopmentPanel: defineAsyncComponent(() => import("@/components/DevelopmentPanel.vue")),
    DevtoolsConnect: defineAsyncComponent(() => import("@/components/DevtoolsConnect.vue")),
    AuthPopup: defineAsyncComponent(() => import("@/components/auth/AuthPopup.vue")),
  },
  setup() {
    // used by one-design-system
    provide("VUE_ROUTER_USE_ROUTE", useRoute())
    provide("VUE_ROUTER_USE_ROUTER", useRouter())

    // stores
    const appStore = useAppStore()
    const siteStore = useSiteStore()
    const userStore = useUserStore()

    // states
    const {siteVar, siteVarFetched, siteLangCurrent} = storeToRefs(siteStore)

    // getters
    const {themeClass: appThemeClass, mode: appMode} = storeToRefs(appStore)
    const {hasShop: siteHasShop} = storeToRefs(siteStore)

    // actions
    const {listenAuth: userListenAuth} = userStore

    // meta tags
    const meta = computed(() => {
      const lang = siteLangCurrent.value || "en"
      const title = siteVar.value.global.site.title?.[lang] || false
      const description = siteVar.value.global.site.description?.[lang] || false

      return {
        ...(title && {title}),
        ...(description && {description}),
      }
    })

    useMeta(meta.value)

    return {
      meta,
      siteVar,
      siteVarFetched,
      siteHasShop,
      appThemeClass,
      appMode,
      userListenAuth,
    }
  },
  watch: {
    siteVarFetched: {
      handler() {
        if (this.siteVarFetched) this.init()
      },
    },
  },
  data: () => ({
    injected: {
      tracking: null,
      styles: null,
    },
    injectedScriptsReady: false,
  }),
  computed: {
    showRouterView() {
      return this.siteVarFetched && this.injectedScriptsReady && this.appMode != "design"
    },
    showDesignSystem() {
      return this.appMode == "design"
    },
    showDevelopmentPanel() {
      return import.meta.env.VITE_ENVIRONMENT == "local_dev" && this.appMode != "admin"
    },
    showDevtoolsConnect() {
      // return false
      return (
        (import.meta.env.VITE_ENVIRONMENT == "local_dev" && this.appMode == "admin") ||
        localStorage.getItem("@platformaone/client/debug") === "true" ||
        import.meta.env.VITE_USE_EXTERNAL_DEVTOOLS === "true"
      )
    },
  },
  created() {
    if (this.siteVarFetched) this.init()
  },
  methods: {
    init() {
      console.log("init", import.meta.env)
      sinkInit()

      initPlatformaone({
        scope: this.siteVar.scope,
      })

      // when running in cloud, this is prerendered in index.html
      if (["local_dev", "development"].includes(import.meta.env.VITE_ENVIRONMENT)) {
        console.log("client mode:", this.appMode)

        this.injectStylesToHead()

        if (this.appMode != "design") {
          this.injectTrackingToHead()

          // pause all videos to save CPU & ears
          setTimeout(() => {
            document.querySelectorAll("video").forEach((video) => {
              // console.log('video', video)
              video.pause()
            })
          }, 2000)

          setTimeout(() => {
            this.injectedScriptsReady = true
          }, 1000)
        }
      } else if (["staging"].includes(import.meta.env.VITE_ENVIRONMENT)) {
        this.injectedScriptsReady = true // because there are none to inject (injected during publish)
      } else {
        this.injectedScriptsReady = true // because there are none to inject (injected during publish)
      }

      // auth
      this.userListenAuth()
    },
    // for development only - on production it is injected by indexTemplate.js
    injectStylesToHead() {
      if (!this.siteVar.styles) return
      console.log("injectStylesToHead()")
      this.injected.styles = {}
      this.injected.styles.custom = document.createElement("style")
      this.injected.styles.custom.dataset.injectedElement = "customStyles"
      this.injected.styles.custom.innerHTML = this.siteVar.styles
      document.head.appendChild(this.injected.styles.custom)
    },
    injectTrackingToHead() {
      console.log("injectTrackingToHead()")
      console.log(this.siteVar.global.site)
      this.injected.tracking = {}

      // google analytics gtag
      if (this.siteVar.global.site.google) {
        console.log("injecting google analytics gtag")
        this.injected.tracking.gtag1 = document.createElement("script")
        this.injected.tracking.gtag1.dataset.injectedElement = "gtag1"
        this.injected.tracking.gtag1.src = `https://www.googletagmanager.com/gtag/js?id=${this.siteVar.global.site.google.analytics.trackingId}`
        this.injected.tracking.gtag1.async = true
        document.head.appendChild(this.injected.tracking.gtag1)
        this.injected.tracking.gtag2 = document.createElement("script")
        this.injected.tracking.gtag2.dataset.injectedElement = "gtag2"
        this.injected.tracking.gtag2.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${this.siteVar.global.site.google.analytics.trackingId}');
        `
        document.head.appendChild(this.injected.tracking.gtag2)
      }

      // facebook pixel
      if (this.siteVar.global.site.facebook) {
        console.log("injecting facebook pixel")
        this.injected.tracking.facebookPixel = document.createElement("script")
        this.injected.tracking.facebookPixel.dataset.injectedElement = "facebookPixel"
        this.injected.tracking.facebookPixel.innerHTML = `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${this.siteVar.global.site.facebook.pixel.id}');
          fbq('track', 'PageView');
        `
        document.head.appendChild(this.injected.tracking.facebookPixel)
      }

      // smartlook
      if (this.siteVar.global.site.smartlook && import.meta.env.VITE_ENVIRONMENT != "local_dev") {
        console.log("injecting smartlook")
        this.injected.tracking.smartlook = document.createElement("script")
        this.injected.tracking.smartlook.dataset.injectedElement = "smartlook"
        this.injected.tracking.smartlook.innerHTML = `
          window.smartlook||(function(d) {
          var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
          var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
          c.charset='utf-8';c.src='https://rec.smartlook.com/recorder.js';h.appendChild(c);
          })(document);
          smartlook('init', '${this.siteVar.global.site.smartlook.id}');
        `
        document.head.appendChild(this.injected.tracking.smartlook)
      }
    },
  },
}
</script>

<style lang="less">
@import "fonts/fonts.less";
</style>
