import {watch, watchEffect} from "vue"
import {createRouter, createWebHistory} from "vue-router"
import Langs from "@/langs"
import Debug from "@/components/Debug.vue"
import {useAppStore} from "@/stores/app.js"
import {useSiteStore} from "@/stores/site.js"
import {useShopItemsStore} from "@/stores/shopItems.js"
import {pageView} from "@/sink/index.js"
import * as landing from "./landing/index.js"
import * as features from "./features/index.js"
import {useSession} from "@/lib/auth-client"

var routerInstance = null

const r = {
  init: () => {
    const appStore = useAppStore()
    const siteStore = useSiteStore()
    const shopItemsStore = useShopItemsStore()
    const session = useSession()

    const siteVar = siteStore.siteVar
    let siteLanding = siteVar.global.site?.landing

    // TODO: remove
    // temporary during migration
    // siteLanding not yet available -> infer from platform
    const platform = siteVar.global.platform
    console.log({siteLanding, platform})
    if (!siteLanding) {
      if (["qasima.cz", "qasima.ae"].includes(platform)) {
        siteLanding = "preset1"
      } else {
        siteLanding = "custom"
      }
    }

    const siteFeatures = siteVar.features
    const routes = []

    console.log({siteLanding, siteFeatures})

    // if (siteFeatures?.appClient?.userArea) {
    //   watch(session, observeSession, {immediate: true})
    // }

    // auth
    if (siteLanding === "auth") {
      landing.auth({routes})
    }
    // preset 1 (former 'qasima' storefront - logo, title, products, footer)
    else if (siteLanding === "preset1") {
      landing.preset1({routes, siteVar, shopItemsStore})
    }
    // custom
    else if (siteLanding === "custom") {
      landing.custom({routes, siteVar, siteStore, shopItemsStore})
    }
    // empty
    else {
      landing.empty({routes})
    }

    // account (user area)
    const routeAccount = {
      path: `/account`,
      name: `AccountView`,
      component: () => import("../views/AccountView.vue"),
      meta: {
        requiresAuth: true,
      },
      children: [],
    }

    // features: shop
    if (siteFeatures?.global?.shop) {
      features.shop({routes, siteFeatures, routeAccount})
    }

    // features: balance
    if (siteFeatures?.global?.balance) {
      features.balance({routeAccount, siteVar})
    }

    // entitlement public view (available for all sites/merchants)
    routes.push({
      path: "/:lang?/code/:code",
      name: "EntitlementPublicView",
      component: () => import("../views/EntitlementPublicView.vue"),
      meta: {},
    })

    routes.push(routeAccount)

    // debug
    routes.push({
      path: "/debug",
      name: "debug",
      component: Debug,
      meta: {},
    })

    // create router
    routerInstance = createRouter({
      history: createWebHistory(),
      routes,
      scrollBehavior(to, from, savedPosition) {
        console.log("savedPosition:", savedPosition)
        return new Promise((resolve) => {
          setTimeout(() => {
            let res = {}
            if (savedPosition) {
              res = savedPosition
            } else {
              res = {top: 0, left: 0}
            }
            resolve(res)
          }, 250)
        })
      },
    })

    // before each route
    routerInstance.beforeEach(async (to, from, next) => {
      const requiresAuth = to.matched.some((route) => route.meta.requiresAuth)

      console.log("🔴 beforeEach()", {
        requiresAuth,
        from,
        to,
      })

      // route requires auth
      if (requiresAuth) {
        // session pending -> wait
        if (session.value.isPending) {
          console.log("🔴 session pending, waiting for it to resolve")
          await new Promise((resolve) => watchEffect(() => !session.value.isPending && resolve()))
          console.log("🔴 session resolved")
        }

        const isSignedIn = !!session.value?.data?.user?.id

        // user not signed in -> redirect to auth
        if (!isSignedIn) {
          console.log("🔴 redirecting to auth")
          next("/?authDialog=true") // can't use LandingView, because landing 'custom' uses generated names
        }
      }

      // set last product detail opened from
      if (to.params.productSKU && !from.params.productSKU) {
        appStore.app.lastProductDetailOpenedFrom = from
      }

      // set page lang
      if (to.meta.lang && to.meta.lang != siteStore.siteLangCurrent) {
        siteStore.setCurrentLang({lang: to.meta.lang})
        Langs.setLang(to.meta.lang)
        console.log("switching to lang:", to.meta.lang)
      }

      // set page lang
      if (siteLanding === "preset1") {
        if (to.params.lang) {
          siteStore.setCurrentLang({lang: to.params.lang})
          Langs.setLang(to.params.lang)
          console.log("switching to lang:", to.params.lang)
        }
      }
      next()
    })

    // after each route
    routerInstance.afterEach((to, from, failure) => {
      // log page view
      if (!failure)
        pageView({
          to: {
            name: to.name,
            path: to.path,
            params: to.params,
            query: to.query,
            meta: to.meta,
          },
          from: {
            name: from.name,
            path: from.path,
            params: from.params,
            query: from.query,
            meta: from.meta,
          },
        })
    })
  },

  r: () => {
    return routerInstance
  },
}

export const router = r

async function observeSession(session) {
  if (session.isPending === false) {
    console.log("🔴 session not pending", session)

    // session data -> signed in
    if (session.data !== null) {
      // console.log("🔴 session signed in -> AccountView")
      // routerInstance.push({name: "AccountView"})
    }
    // no session data -> not signed in
    else {
      console.log("🔴 session not signed in -> LandingView")
      routerInstance.push("/") // not using LadingView, because landing 'custom' uses generated names
    }
  }
}
