export function custom({routes, siteVar, siteStore, shopItemsStore}) {
  const sitePages = siteVar.pages
  let pagesWithProductDetailBlock = []
  let availableLangs = siteStore.siteLangsAvailable

  // find pages containing product detail block, so we can catch the product sku param in url
  sitePages.forEach((page) => {
    if (page.containsProductDetailBlock) {
      shopItemsStore.itemDetailPageId = page.id
      pagesWithProductDetailBlock.push(page.id)
    }
  })

  console.log({availableLangs})
  // construct site routes
  availableLangs.forEach((lang) => {
    sitePages.forEach((p) => {
      const pageContainsProductDetailBlock = pagesWithProductDetailBlock.includes(p.id)
      const pageRoute = getPageRoute(p.id, sitePages, lang)
      let fixedProduct = null
      let route = {}

      // if page has product detail block, check if the product is fixed
      if (pageContainsProductDetailBlock) {
        p.blocks.forEach((b) => {
          if (b.config.fixedProduct && b.config.fixedProductSKU)
            fixedProduct = b.config.fixedProduct
        })
      }

      // landing (homepage)
      if (pageRoute == "/") {
        route = {
          path: `/${lang}`,
          name: `${p.id}-${lang}`,
          component: () => import("@/components/Page.vue"),
          meta: {
            id: p.id,
            slug: "/",
            lang,
          },
        }
      } else {
        route = {
          path:
            pageContainsProductDetailBlock && !fixedProduct
              ? `/${lang}${pageRoute}/:productSKU`
              : `/${lang}${pageRoute}`,
          name: `${p.id}-${lang}`,
          component: () => import("@/components/Page.vue"),
          meta: {
            id: p.id,
            slug: `/${lang}${pageRoute}`,
            lang,
          },
        }
      }
      routes.push(route)

      // pages in default lang also have route without lang specified
      if (lang == siteStore.siteLangDefault) {
        route = {
          path:
            pageContainsProductDetailBlock && !fixedProduct
              ? `${pageRoute}/:productSKU`
              : `${pageRoute}`,
          name: `${p.id}-default`,
          component: () => import("@/components/Page.vue"),
          meta: {
            id: p.id,
            slug: `${pageRoute}`,
            lang,
          },
        }
        routes.push(route)
      }
    })
  })
}

function getPageRoute(pageId, all, lang) {
  let route = "/"
  const page = all.find((p) => p.id == pageId)
  if (page !== undefined && page.slug !== undefined) {
    route += page.slug[lang]
    // Try to get parent path
    if (page.treeParentId != "root") {
      let parentPath = getPageRoute(page.treeParentId, all, lang)
      if (parentPath != "/") {
        route = parentPath + route
      }
    }
  }
  return route
}
