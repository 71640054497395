export function preset1({routes, siteVar, shopItemsStore}) {
  routes.push({
    path: "/:lang?",
    name: "LandingView",
    component: () => import("@/views/LandingPreset1View.vue"),
    meta: {
      lang: siteVar.lang.default,
    },
    children: [
      {
        path: "product/:productSKU",
        alias: "produkt/:productSKU",
        name: "ProductDetailView",
        component: () => import("@/views/LandingPreset1ProductDetailView.vue"),
        meta: {},
      },
      {
        path: "tos",
        name: "TermsView",
        component: () => import("@/views/LandingPreset1TermsView.vue"),
        meta: {},
      },
    ],
  })
  shopItemsStore.itemDetailPageId = "ProductDetailView"
}
