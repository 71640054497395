import {router} from "@/router"
import Langs from "@/langs"
import {useSiteStore} from "@/stores/site.js"
import axios from "axios"
import sentry from "@/plugins/sentry"

// regular client web view
export default {
  install(app) {
    // we're at c.pfm.pm/CODE -> fetch entitlement by code and redirect to shop domain
    if (["c.pfm.pm"].includes(window.location.hostname)) {
      const code = window.location.pathname.split("/").pop()
      const baseUrl = import.meta.env.VITE_URL_API
      const endpoint = "v1/entitlements"
      const url = `${baseUrl}/${endpoint}?code=${encodeURIComponent(code)}`

      console.log("fetching entitlement:", {code, url})

      // using direct url fetch because c.pfm.pm is not pointed to load balancer like other domains, so '/api' path does not work
      fetch(url)
        .then((res) => res.json())
        .then((res) => {
          const entitlement = res.data.entitlement
          const shopDomain = entitlement.shopDomain
          const redirectUrl = `https://${shopDomain}/code/${code}`
          console.log("🔴 redirecting to:", redirectUrl)

          if (import.meta.env.VITE_ENVIRONMENT !== "local_dev") {
            window.location.href = redirectUrl
          }
        })

      return
    }

    const siteStore = useSiteStore()

    console.log("installing modeClient", window.site)

    const url = `${import.meta.env.VITE_URL_STATIC}/sitevar/${window.site.id}`
    console.log("fetching siteVar from", url)

    axios
      .get(url, {withCredentials: false})
      .then((res) => {
        // console.log("fetched siteVar:", res.data, window.site.id)
        // res.data.lang.current = window.site.lang.current // this does not seem to be used

        Langs.setLang(res.data.lang.current)

        siteStore.setSiteVar({siteVar: res.data})

        router.init(app)

        app.use(router.r())
        app.use(sentry)
        app.mount("#app")
      })
      .catch((err) => {
        console.error(err)
      })
  },
}
